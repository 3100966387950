<template>
  <section>
    <p class="has-text-weight-bold mb-2">Sales history</p>

    <!--  <b-field
       grouped
       group-multiline
       class="field"
       :class="{ 'is-invisible': !checkedRows.length }"
     >
       <b-button
         label="Clear selection"
         type="is-danger"
         icon-left="times"
         @click="checkedRows = []"
         class="mr-2"
       />
       <b-button
         label="Close day"
         :loading="closing"
         type="is-primary"
         :disabled="!checkedRows.length"
         @click="closeDay"
       />
     </b-field> -->

    <b-table
      class="pt-4"
      :data="salesHistory"
      ref="table"
      paginated
      per-page="10"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      pagination-rounded
    >
      <!--  <b-table-column
         field="tourName"
         label="Tour name"
         sortable
         v-slot="props"
       >
         {{ getActivityInfo(props.row.activityId).title }}
       </b-table-column> -->

      <b-table-column field="date" label="Date" v-slot="props">
        {{ props.row.date }}
      </b-table-column>

      <b-table-column
        field="amountCard"
        label="Card amount"
        sortable
        v-slot="props"
      >
        {{ props.row.amountCard }} €
      </b-table-column>

      <b-table-column
        field="amountCash"
        label="Cash amount"
        sortable
        v-slot="props"
      >
        {{ props.row.amountCash }} €
      </b-table-column>

      <b-table-column
      field="commissionEarned"
      label="Commission Earned"
      sortable
      v-slot="props"
    >
      {{ props.row.commission }} €
    </b-table-column>

      <b-table-column
        field="ticketsSold"
        label="Tickets sold"
        sortable
        v-slot="props"
      >
        {{ props.row.ticketsSold }}
      </b-table-column>

      <b-table-column
          label="Status"
          sortable
          v-slot="props"
        >
          <b-tag v-if="props.row.isClosed" type="is-success">Closed</b-tag>
          <b-tag v-else type="is-danger">Needs attention</b-tag>
        </b-table-column>

      <b-table-column v-slot="props">
        <b-button
        type="is-primary"
          :disabled="props.row.isClosed"
          @click="payCommission(props.row.ids)"
          >Mark as closed</b-button
        >
      </b-table-column>
     
      <template #empty>
        <div class="has-text-centered">
          This vendor hasn't sold any tickets yet
        </div>
      </template>
    </b-table>
  </section>
</template>

<script>
import { db } from '../firebase';
export default {
  props: {
    vendor: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      checkedRows: [],
      closing: false,
      isLoading: true,
      salesHistory: [],
      storedSalesHistory: []
    };
  },
  watch: {
    vendor() {
      this.storedSalesHistory = this.vendor.salesHistory
      this.processSalesData(this.vendor.salesHistory);
    },
  },
  created() {
    this.isLoading = false;
  },
  methods: {
    checkIfNeedsAttention(ids){
      return ids.every(ticketId => {
        const ticket = this.storedSalesHistory.find(t => t.ticketId === ticketId);
        return ticket && ticket.paid === true;
      });
    },
    processSalesData(sales) {
      // Convert timestamp to JavaScript Date object
      this.salesHistory = []
      function convertTimestamp(timestamp) {
        if (typeof timestamp === "string") {
          return new Date(timestamp); // Directly convert ISO string to Date
        }

        // Otherwise, assume it's in Firestore format with seconds and nanoseconds
        return new Date(
          timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6)
        );
      }

      // Format date as DD-MM-YYYY
      function formatDate(dateObj) {
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
      }

      // Sort by date
      sales.sort(
        (a, b) =>
          convertTimestamp(a.dateOfPurchase) -
          convertTimestamp(b.dateOfPurchase)
      );

      // Group sales by date
      const groupedSales = sales.reduce((acc, sale) => {
        const dateObj = convertTimestamp(sale.dateOfPurchase);
        const date = formatDate(dateObj); // Convert to DD-MM-YYYY

        if (!acc[date]) {
          acc[date] = { amountCard: 0, amountCash: 0, ticketsSold: 0, ids: [] , commission:0};
        }

        // Add to correct payment method
        if (sale.selectedPaymentOption === "Credit Card") {
          acc[date].amountCard += sale.totalPrice;
        } else if (sale.selectedPaymentOption === "Cash") {
          acc[date].amountCash += sale.totalPrice;
        }
        if (sale.commission)  acc[date].commission += sale.commission;
        
        // Count tickets sold
        acc[date].ticketsSold += sale.numberOfTickets;
        acc[date].ids.push(sale.ticketId);
        return acc;
      }, {});
      this.salesHistory = Object.entries(groupedSales).map(([date, data]) => ({
        date,
        amountCard: data.amountCard,
        amountCash: data.amountCash,
        ticketsSold: data.ticketsSold,
        ids: data.ids,
        commission: data.commission,
        isClosed: this.checkIfNeedsAttention(data.ids)
      }));
    },
    async payCommission(ids) {
      try {
        const vendorRef = db
          .firestore()
          .collection("vendors")
          .doc(this.vendor.id);
        const vendorSnapshot = await vendorRef.get();

        if (!vendorSnapshot.exists) {
          console.error("❌ Vendor not found!");
          return;
        }

        const vendorData = vendorSnapshot.data();
        if (
          !vendorData.salesHistory ||
          !Array.isArray(vendorData.salesHistory)
        ) {
          console.error("❌ No sales history found!");
          return;
        }

        let updated = false;

        // Loop through each element in salesHistory and update paid if ticketId is in ids array
        const updatedSalesHistory = vendorData.salesHistory.map((sale) => {
          if (ids.includes(sale.ticketId)) {
            updated = true;
            return { ...sale, paid: true };
          }
          return sale;
        });

        // Only update Firestore if there were changes
        if (updated) {
         await vendorRef.update({ salesHistory: updatedSalesHistory });
         this.storedSalesHistory = updatedSalesHistory
         this.processSalesData(updatedSalesHistory)
         
          console.log("✅ All matching tickets updated successfully!");
        } else {
          console.log("⚠️ No matching tickets found.");
        }
      } catch (error) {
        console.error("❌ Error updating tickets:", error);
      }
    },
   
  },
};
</script>
