<template>
  <section class="container-md">
    <div v-if="isLoading">
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
    <section v-else>
      <GoBack class="mb-5" />

      <div class="container--info mb-5">
        <div class="columns">
          <div class="column is-6 mr-1">
            <div class="display-flex-space-btw">
              <p class="has-text-weight-medium">Name</p>
              <div class="display-flex-space-btw">
                <p class="vendor-details">
                  {{ vendor.displayName || vendor.name }}
                </p>
                <div @click="copyTextToClipboard(vendor.id)">
                  <b-icon
                    :class="{ 'is-invisible': !vendor.id }"
                    pack="far"
                    class="ml-2 icon--light-grey"
                    icon="copy"
                  ></b-icon>
                </div>
              </div>
            </div>

            <div class="display-flex-space-btw">
              <p class="has-text-weight-medium">Vendor ID</p>
              <div class="display-flex-space-btw">
                <p class="vendor-details">{{ vendor.id || "Unknown" }}</p>
                <div @click="copyTextToClipboard(vendor.id)">
                  <b-icon
                    :class="{ 'is-invisible': !vendor.id }"
                    pack="far"
                    class="ml-2 icon--light-grey"
                    icon="copy"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-6">
            <div class="display-flex-space-btw">
              <p class="has-text-weight-medium">Phone Number</p>
              <div class="display-flex-space-btw">
                <p class="vendor-details">
                  {{ vendor.phoneNumber || "Unknown" }}
                </p>
                <div @click="copyTextToClipboard(vendor.phoneNumber)">
                  <b-icon
                    :class="{ 'is-invisible': !vendor.phoneNumber }"
                    pack="far"
                    class="ml-2 icon--light-grey"
                    icon="copy"
                  ></b-icon>
                </div>
              </div>
            </div>

            <div class="display-flex-space-btw">
              <p class="has-text-weight-medium">Email</p>
              <div class="display-flex-space-btw">
                <p class="vendor-details">{{ vendor.email || "Unknown" }}</p>
                <div @click="copyTextToClipboard(vendor.email)">
                  <b-icon
                    :class="{ 'is-invisible': !vendor.email }"
                    pack="far"
                    class="ml-2 icon--light-grey"
                    icon="copy"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DriverSalesTable
        :vendor="vendor"
        v-if="vendor?.role === ResellerRoles.driver"
      />
      <CommisionsTable
        v-else
        is-admin-view
        :activities="activities"
        :sales="salesHistory"
        :role="vendor?.role"
        :vendor-id="vendor?.id"
      />
    </section>
  </section>
</template>

<script>
import { db } from "../firebase";
import GoBack from "../components/elements/GoBack.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { ResellerRoles } from "../enums/ResellerRoles";
import {
  VendorsStates,
  VendorsActions,
  ActivitiesMutations,
  ActivitiesStates,
} from "../store/Storetypes";
import { ActivityMixin, HelpersMixin } from "../mixins";
import DriverSalesTable from "./DriverSalesTable.vue";
import CommisionsTable from "../Vendor/CommisionsTable.vue";
export default {
  components: {
    GoBack,
    DriverSalesTable,
    CommisionsTable,
  },
  mixins: [ActivityMixin, HelpersMixin],
  data() {
    return {
      checkedRows: [],
      closing: false,
      isLoading: true,
      salesHistory: [],
      ResellerRoles,
    };
  },
  created() {
    this.getActivityData();
    this.getVendorData(this.$route.params.vendorId);
    this.getVendorSales();
    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      getActivityData: ActivitiesMutations.GET_ACTIVITY_DATA,
    }),
    ...mapActions({
      getVendorData: VendorsActions.GET_VENDOR_DATA,
    }),
    copyTextToClipboard(text) {
      if (!text) return;
      this.copyToClipboard(text);
    },
    async getVendorSales() {
      const vendorData = await db
        .firestore()
        .collection("vendors")
        .doc(this.$route.params.vendorId)
        .get();

      if (vendorData.exists) {
        if (!vendorData.data()?.salesHistory.length) return;
        this.salesHistory = vendorData.data().salesHistory;
        console.log(this.salesHistory);
      }
    },
  },
  computed: {
    ...mapState({
      vendor: (state) => state.vendors[VendorsStates.SELECTED_VENDOR_DATA],
      activities: (state) => state.activities[ActivitiesStates.ACTIVITIES],
    }),
  },
};
</script>

<style lang="scss" scoped>
.container--info {
  display: block;
  padding: 1rem;

  .columns {
    align-items: flex-start;
  }
}

.vendor-details {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  max-width: 10rem;
  color: #373f4f;
  font-weight: 300;
}
</style>
